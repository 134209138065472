import { ReactElement, useEffect } from 'react';
import styles from './Header.module.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import HeaderMenu from './HeaderMenu';
import HeaderProfile from './HeaderProfile';
import perlaLogo from '../assets/images/Logo.svg';
import { useState } from 'react';

export default function Header({ logedInUser, logoutProccess }): ReactElement {
	const history = useHistory();
	const pathName = useLocation().pathname;
	const [bg, setBg] = useState(
		['/', '/landing', '/landing/', '/plans', '/plans/'].includes(pathName)
			? `transparent`
			: '#ffffff',
	);
	const setHesaderTopStyle = () => {
		if (window.pageYOffset > 0) {
			setBg(`#ffffff`);
		} else {
			setBg(
				['/', '/landing', '/landing/', '/plans', '/plans/'].includes(
					pathName,
				)
					? `transparent`
					: '#ffffff',
			);
		}
	};
	useEffect(() => {
		setHesaderTopStyle();
	}, []);

	document.body.onscroll = e => {
		setHesaderTopStyle();
	};
	return (
		<div className={styles.root} style={{ backgroundColor: bg }}>
			<Link to="/" className={styles.logoContainer}>
				<img src={perlaLogo} alt="Perla" />
			</Link>
			<div className={styles.btnsContainer}>
				<HeaderMenu logedInUser={logedInUser} />
				{logedInUser.token && logedInUser.token.length > 0 ? (
					<HeaderProfile
						showMedicalMenu={
							logedInUser.company?.verified_by_perla ===
							'approved'
						}
						logedInUser={logedInUser}
						logoutProccess={() => {
							logoutProccess(() => {
								history.push('/');
							});
						}}
					/>
				) : process.env.REACT_APP_TYPE === 'terms' ? (
					<a
						href={`${process.env.REACT_APP_PERLA_LINK}signin/client`}
					>
						Log In
					</a>
				) : (
					<Link to="/signin/client">Log In</Link>
				)}
			</div>
		</div>
	);
}
