const BaseApi = process.env.REACT_APP_BASE_API;

const Apis = {
	CATEGORIES: `${BaseApi}/app/categories`,
	MY_PROFILE: `${BaseApi}/app/my-profile`,
	SIGNIN: `${BaseApi}/signin-linkedin`,
	LOGOUT: `${BaseApi}/app/logout`,
	ADVISORS: `${BaseApi}/app/advisors`,
	REVIEWS: `${BaseApi}/app/reviews`,
	MY_HISTORIES: `${BaseApi}/app//my-histories`,
	MY_REVIEWS: `${BaseApi}/app//my-reviews`,
	MY_MESSAGES: `${BaseApi}/app//my-messages`,
	SEND_MESSAGE: `${BaseApi}/app/message`,
	ADD_REVIEW: `${BaseApi}/app/review`,
	SIGNUP_WITH_EMAIL: `${BaseApi}/app/signup`,
	VERIFY_CODE: `${BaseApi}/app/verify-code`,
	RESEND_CODE: `${BaseApi}/app/resend-code`,
	SIGNIN_EMAIL: `${BaseApi}/app/signin`,
	FORGET_PASSWORD: `${BaseApi}/app/forget-password`,
	CHANGE_PASSWORD: `${BaseApi}/app/change-password`,

	ADD_LOCATION: `${BaseApi}/app/locations`,
	LOCATIONS: `${BaseApi}/app/locations`,
	LOCATION: `${BaseApi}/app/locations`,
	DELETE_LOCATION: `${BaseApi}/app/locations`,
	MAKE_PRIMARY_LOCATION: `${BaseApi}/app/make-primary-location`,
	ADD_USER: `${BaseApi}/app/users`,
	USERS: `${BaseApi}/app/users`,
	DELETE_USER: `${BaseApi}/app/users`,
	USER: `${BaseApi}/app/users`,
	UPLOAD_DOCUMENT: `${BaseApi}/app/upload-document`,
	ADD_DOCUMENT: `${BaseApi}/app/documents`,
	DOCUMENTS: `${BaseApi}/app/documents`,
	ADD_TRANSFER_OWNERSHIP: `${BaseApi}/app/transferownerships`,
	TRANSFER_OWNERSHIPS: `${BaseApi}/app/transferownerships`,
	PAYMET_METHODS: `${BaseApi}/app/payment-methods`,
	PAYMET_ACCOUNTS: `${BaseApi}/app/payment-accounts`,
	SUBSCRIPTIONS: `${BaseApi}/app/subscriptions`,
	ADD_COMPANY: `${BaseApi}/app/companies`,
	UPDATE_USER: `${BaseApi}/app/users`,
	UPDATE_LOCATION: `${BaseApi}/app/locations`,
	EQUIPMENTS: `${BaseApi}/app/equipments`,
	ADD_EQUIPMENT: `${BaseApi}/app/equipments`,
	NUMBER_OF_DOCUMENTS: `${BaseApi}/app/numberOfDocuments`,
	NUMBER_OF_USERS_AND_LOCATIONS: `${BaseApi}/app/numberOfUsersandLocations`,
	ADD_CREDIT_CARD: `${BaseApi}/app/creditCards`,

	CANCEL_TRANFER_LOCATION_OWNERSHIP: `${BaseApi}/app/transferownerships`,
	LOCATIONS_OF_TRANSFER: `${BaseApi}/app/transferownerships`,
	USERS_OF_LOCATION: `${BaseApi}/app/transferownerships`,
	TRANSFER_ACCOUNT_OWNERSHIP: `${BaseApi}/app/transferownerships`,
	ADD_PAYMENT_METHOD: `${BaseApi}/app/transferownerships`,
	ADD_BACKUP_PAYMENT_METHOD: `${BaseApi}/app/transferownerships`,
	UPDATE_PAYMENT_METHOD: `${BaseApi}/app/transferownerships`,
	DELETE_SUBSCRIPTION: `${BaseApi}/app/transferownerships`,
	UPGRADE_SUBSCRIPTION: `${BaseApi}/app/transferownerships`,
	DOWNGRADE_SUBSCRIPTION: `${BaseApi}/app/transferownerships`,
	REFERRALS: `${BaseApi}/app/transferownerships`,
	REFER_A_COLLEAGUE: `${BaseApi}/app/transferownerships`,
	COUNTRIES: `${BaseApi}/app/transferownerships`,
};
export { BaseApi, Apis };
